import React, { FC } from 'react'
import { Link } from 'gatsby'

import { Layout, Seo, Box, Flex, Text } from 'src/components'

const NotFoundPage: FC = () => (
  <Layout>
    <Seo title="404: Not found" />

    <Box
      width="100%"
      maxWidth={['400px', '640px']}
      border="solid"
      borderRadius="l"
      py={['36px', '66px']}
      mt={['70px', '170px']}
      mx="16px"
      height="fit-content"
      boxShadow="l"
      bg="background"
    >
      <Flex flexDirection="column" alignItems="center">
        <Text fontWeight="bold" fontSize={['xlMob', 'xl']} textAlign="center">
          NOT FOUND
        </Text>

        <Box my="16px">
          <Flex flexDirection="column" alignItems="center">
            <Text fontSize={['lMob', 'l']} textAlign="center">
              You just hit a route that doesn&#39;t exist... the sadness.
            </Text>
          </Flex>
        </Box>

        <Text as={Link} to="/">
          Go to Main page
        </Text>
      </Flex>
    </Box>
  </Layout>
)

export default NotFoundPage
